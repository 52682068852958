import { Col, Row } from "antd";
import CareLibraryIcon from "./../../assets/Login/care-library-icon.png";
import LazyIcon from "./../../assets/Login/lazy-icon-care.png";
import { Form, Input, Button } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useResetPasswordRequestMutation } from "../../store/Slices/Signin";
import Footer from "../../layout/Footer/Footer";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import AppSnackbar from "../../utils/AppSnackbar";
import "./SignIn.scss";
//comment for testing
const ResetPassword = () => {
  const { state }: any = useLocation();
  const navigate = useNavigate()
  const [resetPasswordRequest, { isLoading }] =
    useResetPasswordRequestMutation();

  const onFinish = async (values: any) => {
    const payload = {
      email: state.email,
      session: state.session,
      password: values.password,
    };
    try {
      await resetPasswordRequest({ payload }).unwrap();
      AppSnackbar({
        type: "success",
        messageHeading: "Password reset successful",
        message: "Please check your Email for Identity Verification.",
      });
      navigate("/login")
    } catch (error: any) {
      AppSnackbar({
        type: "error",
        messageHeading: "Error",
        message: error?.data?.message ?? "Something went wrong!",
      });
    }
  };

  const validateEmail = (rule: any, value: any, callback: any) => {
    if (!value) {
      callback();
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        callback();
      } else {
        callback("Invalid format of email");
      }
    }
  };

  return (
    <Row className="care-signin">
      {/* Left Div */}
      <Col xs={0} sm={0} lg={12} xl={14}>
        <div className="left-outer-div">
          <div className="inner-left-div">
            <div>
              <h1 className="heading-1">
                <span className="pink-color">Reset Password</span>
                <span> to</span>
              </h1>
              <h3 className="heading-3">Care Library</h3>
            </div>
            <div className="demo-wrap">
              <div className="demo-content">
                <img src={LazyIcon} alt="care-library-icon" />
              </div>
            </div>
          </div>
        </div>
      </Col>
      {/* Right Div for form */}
      <Col xs={24} sm={24} lg={12} xl={10}>
        <div className="right-outer-div">
          <div className="img-div">
            <img
              src={CareLibraryIcon}
              alt="care-library-icon"
              width={199}
              height={91}
            />
          </div>
          <div>
            <h2 className="Sign-in-heading">Reset Password</h2>
            <Form name="email" onFinish={onFinish}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Required field",
                  },
                  //  { validator: validateEmail }
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  className="input-style"
                // iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Required field",
                  },
                ]}
              >
                {/* <Input.Password placeholder="Password" /> */}
                <Input.Password
                  placeholder="Confirm Password"
                  className="input-style"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <div style={{ textAlign: "end", margin: "10px 0px 20px 0px" }}>
                <Link to="" className="forgot-password-style">
                  Forgot Password?
                </Link>
              </div>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  className=" btn-signin fw-600 "
                  block
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
            <Footer />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ResetPassword;
