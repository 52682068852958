export const theme = {
  token: {
    colorPrimary: "#65CDF0",
    fontFamily: "Poppins,sans-serif",

  },
  components: {
    Button: { borderRadius: 2, controlHeight: 40, controlHeightLG: 48, controlHeightSM: 24, paddingContentHorizontal: 35, fontSize: 16, colorPrimaryHover: "none" },
    Input: { controlHeight: 45, controlHeightLG: 45, controlHeightSM: 38, colorBorder: "#A0A3BD", borderRadius: 3 },

  },
};
