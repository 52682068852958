import { Col, Row } from "antd";
import CareLibraryIcon from "./../../assets/Login/care-library-icon.png";
import LazyIcon from "./../../assets/Login/lazy-icon-care.png";
import { useState } from "react";
import { Form, Input, Button } from "antd";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useForgotPasswordRequestMutation, useSignInPostRequestMutation } from "../../store/Slices/Signin";
import Footer from "../../layout/Footer/Footer";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useChangePasswordPostRequestMutation } from "../../store/Slices/ChangePassword";
import CareLogo from '../../assets/Login/care-library-icon.png'
import "./SignIn.scss";
//comment for testing
const Login = () => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [changePasswordErrorMessage, setChangePasswordErrorMessage] =
    useState("");
  let navigate = useNavigate();
  const location = useLocation();
  const [signInPostRequest, { isLoading }] = useSignInPostRequestMutation();
  const [changePasswordPostRequest] =
    useChangePasswordPostRequestMutation();
    const [searchParam,setSearchParam]=useSearchParams()

  //Forgot Password Query
  const [forgotPasswordRequest, { isLoading: isLoadingForgotPassword }] = useForgotPasswordRequestMutation()




  function renderDashboard(role: string): string {
    if (role === "carer") {
      return "/carer-dashboard";
    } else if (role === "carer_coordinator") {
      return "/coordinator-dashboard";
    } else if (role === "client") {
      return "/client-dashboard";
    } else if (role === "training_instructor") {
      return "/instructor-dashboard";
    } else if (role === "system_admin") {
      return "/super-admin-dashboard";
    } else {
      return "/";
    }
  }

  const onFinish = async (values: any) => {
    const payload = {
      email: values.username,
      password: values.password,
    };

    const { error, data }: any = await signInPostRequest({
      payload,
    });

    const role = data?.data?.user?.roleData?.name;

    if (data?.data?.session) {
      navigate(`/reset-password`, {
        state: { session: data?.data?.session, email: values.username },
      });
    } else {
      if (!error) {
        const userData = {
          username: data?.data?.email,
          token: data?.data?.accessToken,
          refreshToken: data?.data?.refreshToken,
          cognitoId: data?.data?.user?.cognitoId,
          id: data?.data?.user?._id,
          roleId: data?.data?.user?.roleId,
          role,
          permissions: data?.data?.user?.roleData?.permissions,
          name: data?.data?.user?.firstName ? `${data?.data?.user?.firstName} ${data?.data?.user?.lastName}` : data?.data?.user?.clientName,
        };
        const stringifyData = JSON.stringify(userData);
        localStorage.setItem("careUserData", stringifyData);
        navigate(renderDashboard(role));
      } else {
        setErrorMessage(error?.data?.message);
      }
    }
  };
  const onFinishChangePassword = async (values: any) => {
    if (values?.newPassword === values?.confirmNewPassword) {
      const payload = {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      };
      const { error, data }: any = await changePasswordPostRequest({
        payload,
      });
      if (!error) {
        navigate("/login");
      } else {
        setChangePasswordErrorMessage(error?.data?.message);
      }
    } else {
      setChangePasswordErrorMessage(
        "New Password and Confirm New Password Should Be Equal"
      );
    }
  };
  const onFinishForgotPassword = async (values: any) => {
    await forgotPasswordRequest({
      payload: values
    });
    navigate("/confirm-password", { state: { email: values } })
  }
  const validateEmail = (rule: any, value: any, callback: any) => {
    if (!value) {
      callback();
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        callback();
      } else {
        callback("Invalid format of email");
      }
    }
  };

  console.log('pathname', location);
  // const approvalStatus = location.search.split('=');
  const approvalStatus=searchParam.get("status")
  console.log('approvalStatus', approvalStatus);


  return (
    <Row className="care-signin">


      {approvalStatus ?


        <Col xs={24} style={{ padding: '20px', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

          <img
            src={CareLibraryIcon}
            alt="care-library-icon"
            width={199}
            height={91}
            style={{ position: 'fixed', left: 20, top: 20 }}
          />

          <div>

            <h2 style={{ color: "#4E132C" }}> Your ID Verification Process is <span className="pink-color" style={{ textTransform: 'capitalize' }}>{approvalStatus}</span></h2>
            <p style={{ padding: '0px 0px 0px 20px ', color: "#4E132C" }}>We will inform via email once your verification is completed</p>
          </div>
        </Col>


        :
        <>
          {/* Left Div */}
          <Col xs={0} sm={0} lg={12} xl={14}>
            <div className="left-outer-div">
              <div className="inner-left-div">
                <div>
                  <h1 className="heading-1">
                    <span className="pink-color">
                      {location?.pathname === "/login"
                        ? "Sign In"
                        : location?.pathname === "/forgot-password" ? "Forgot Password" : "Change Password"}
                    </span>
                    <span> to</span>
                  </h1>
                  <h3 className="heading-3">Care Library</h3>
                </div>
                <div className="demo-wrap">
                  <div className="demo-content">
                    <img src={LazyIcon} alt="care-library-icon" />
                  </div>
                </div>
              </div>
            </div>
          </Col>

          {location?.pathname === "/login" && (
            <Col xs={24} sm={24} lg={12} xl={10}>
              <div className="right-outer-div">
                <div className="img-div">
                  <img
                    src={CareLibraryIcon}
                    alt="care-library-icon"
                    width={199}
                    height={91}
                  />
                </div>
                <div>
                  <h2 className="Sign-in-heading">Sign In</h2>
                  <Form name="email" onFinish={onFinish}>
                    <Form.Item
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Required field",
                        },
                        { validator: validateEmail },
                      ]}
                    >
                      <Input placeholder="Username" className="input-style" />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Required field",
                        },
                      ]}
                    >
                      {/* <Input.Password placeholder="Password" /> */}
                      <Input.Password
                        placeholder="Password"
                        className="input-style"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                    <p style={{ color: "red" }}>{errorMessage}</p>

                    <div style={{ textAlign: "end", margin: "10px 0px 20px 0px" }}>
                      <Link to="/forgot-password" className="forgot-password-style">
                        Forgot Password?
                      </Link>
                    </div>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        className=" btn-signin fw-600 "
                        block
                      >
                        Sign In
                      </Button>
                    </Form.Item>
                  </Form>
                  <Footer />
                  {/* <p className="fs-15-n-gray">
              Resend <span className="pink-color">Log In</span> Details
            </p> */}
                </div>
              </div>
            </Col>
          )}
          {/* Change Password */}
          {location?.pathname === "/change-password" && (
            <Col xs={24} sm={24} lg={12} xl={10}>
              <div className="right-outer-div">
                <div className="img-div">
                  <img
                    src={CareLibraryIcon}
                    alt="care-library-icon"
                    width={199}
                    height={91}
                  />
                </div>
                <div>
                  <h2 className="Sign-in-heading">Change Passwod</h2>
                  <Form name="currentPassword" onFinish={onFinishChangePassword}>
                    <Form.Item
                      name="currentPassword"
                      rules={[
                        {
                          required: true,
                          message: "Required field",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Current Password"
                        className="input-style"
                      />
                    </Form.Item>
                    <Form.Item
                      name="newPassword"
                      rules={[
                        {
                          required: true,
                          message: "Required field",
                        },
                      ]}
                    >
                      {/* <Input.Password placeholder="Password" /> */}
                      <Input.Password
                        placeholder="New Password"
                        className="input-style"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirmNewPassword"
                      rules={[
                        {
                          required: true,
                          message: "Required field",
                        },
                      ]}
                    >
                      {/* <Input.Password placeholder="Password" /> */}
                      <Input.Password
                        placeholder="Confirm New Password"
                        className="input-style"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                    <p style={{ color: "red" }}>{errorMessage}</p>
                    <p style={{ color: "red" }}>{changePasswordErrorMessage}</p>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        className=" btn-signin fw-600 "
                        block
                      >
                        Save Password
                      </Button>
                    </Form.Item>
                  </Form>
                  <Footer />
                  {/* <p className="fs-15-n-gray">
              Resend <span className="pink-color">Log In</span> Details
            </p> */}
                </div>
              </div>
            </Col>
          )}
          {/*Forgot Password */}
          {location?.pathname === "/forgot-password" && (
            <Col xs={24} sm={24} lg={12} xl={10}>
              <div className="right-outer-div">
                <div className="img-div">
                  <img
                    src={CareLibraryIcon}
                    alt="care-library-icon"
                    width={199}
                    height={91}
                  />
                </div>
                <div>
                  <h2 className="Sign-in-heading">Forgot Password</h2>
                  <Form name="currentPassword" onFinish={onFinishForgotPassword}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Required field",
                        },
                        { validator: validateEmail },
                      ]}
                    >
                      <Input placeholder="User Email" className="input-style" />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoadingForgotPassword}
                        className=" btn-signin fw-600 "
                        block
                      >
                        Save
                      </Button>
                    </Form.Item>
                    <p style={{ color: "red" }}>{errorMessage}</p>
                    <p style={{ color: "red" }}>{changePasswordErrorMessage}</p>

                  </Form>
                  <Footer />
                  {/* <p className="fs-15-n-gray">
              Resend <span className="pink-color">Log In</span> Details
            </p> */}
                </div>
              </div>
            </Col>
          )}
        </>
      }


    </Row>
  );
};

export default Login;
