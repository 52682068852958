import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { Button, Col, Form, Input, Row } from "antd"
import LazyIcon from "./../../assets/Login/lazy-icon-care.png";
import { useConfirmPasswordRequestMutation } from "../../store/Slices/Signin"
import { useState } from "react"
import "./SignIn.scss";
import { useLocation, useNavigate } from "react-router-dom";
import AppSnackbar from "../../utils/AppSnackbar";

const ConfirmPassword=()=>{
  const [errorMessage, setErrorMessage] = useState(false);
 const navigate=useNavigate()
const {state}=useLocation()
  //Confirm Password Hook
const [confirmPasswordRequest ,{isLoading}]=useConfirmPasswordRequestMutation()

  const onFinishConfirmPassword=async(values:any)=>{
    try{
     await confirmPasswordRequest({
        payload:{email:state?.email?.email,...values}
        }).unwrap();
        AppSnackbar({ type: "success", messageHeading: "Successfully Updated!", message: "Password Updated successfully" });
        navigate("/login")

    }
    catch(error:any){
      AppSnackbar({
        type: "error",
        messageHeading: "Error",
        message: error?.data?.message ?? "Something went wrong!"
      });
    }
    
}

return(
 <Row className="care-signin">
  <Col xs={0} sm={0} lg={12} xl={14}>
        <div className="left-outer-div">
          <div className="inner-left-div">
            <div>
              <h1 className="heading-1">
                <span className="pink-color">
                 Confirm Password
                </span>
                <span> to</span>
              </h1>
              <h3 className="heading-3">Care Library</h3>
            </div>
            <div className="demo-wrap">
              <div className="demo-content">
                <img src={LazyIcon} alt="care-library-icon" />
              </div>
            </div>
          </div>
        </div>
      </Col>
 
 <Col xs={24} sm={24} lg={12} xl={10}>
  
 <div className="right-outer-div">
 <h2 className="Sign-in-heading">Confirm Passwod</h2>
  <Form name="email" onFinish={onFinishConfirmPassword}>
   
  <label>Password</label>
  <Form.Item
    name="password"
    rules={[
      {
        required: true,
        message: "Required field",
      },
    ]}
  >
    {/* <Input.Password placeholder="Password" /> */}
    <Input.Password
      placeholder="Password"
      className="input-style"
      iconRender={(visible) =>
        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
      }
    />
  </Form.Item>
  <label>Code</label>
  <Form.Item
    name="code"
    rules={[
      {
        required: true,
        message: "Required field",
      },
      {
        pattern: /^\d{6}$/,
        message: "Code must be 6 digits.",
      },
    ]}
  
  >
    <Input placeholder="Code" className="input-style" />
  </Form.Item>
  <p style={{ color: "red" }}>{errorMessage}</p>

  <Form.Item>
    <Button
      type="primary"
      htmlType="submit"
      loading={isLoading}
      className=" btn-signin fw-600 "
      block
    >
     Save
    </Button>
  </Form.Item>
</Form>
</div>
  </Col>

 </Row>
)
}
export default ConfirmPassword