import AdditionalDocs from "../assets/icons/OnBoarding/additional-docs.svg";

export interface ICarerDetails {
  key: string;
  id: number;
  firstName: string;
  lastName:string;
  applicationStage:string;
  time: string;
  percentageComplete: number;
  email: string;
  days: number;
  profilePhoto: any;
}
export interface ICarerCordinatorDetails {
  key: string;
  firstName: string;
  lastName:string;
  id: number;
  time: string;
  email: string;
  percentageComplete: number;
  contact: string;
  courses: number;
  profilePhoto: any;
  status: string;
  allocatedCareHomes:string
}

export const CarerProfilePanel = [
  {
    id: 1,
    title: "About the Candidate",
    panelData: [
      {
        id: 1,
        panelChilds: "Personal Info",
      },
      {
        id: 2,
        panelChilds: "Address Details",
      },
      {
        id: 3,
        panelChilds: "Photo for ID Badge",
      },
      {
        id: 4,
        panelChilds: "ID Upload (Passport/DL)",
      },
    ],
  },

  {
    id: 2,
    title: "References",
    panelData: [
      {
        id: 5,
        panelChilds: "Add References",
      },
    ],
  },
  {
    id: 3,
    title: "Training & Work History",
    panelData: [
      {
        id: 6,
        panelChilds: "Training Certificate",
      },
      {
        id: 7,
        panelChilds: "Additional Training Details",
      },
      {
        id: 3,
        panelChilds: "Work Experience",
      },
      {
        id: 4,
        panelChilds: "Specialities",
      },
    ],
  },
  {
    id: 4,
    title: "Background Checks",
    panelData: [
      {
        id: 1,
        panelChilds: "DBS",
      },
      {
        id: 2,
        panelChilds: "Right to Work",
      },
    ],
  },
  {
    id: 5,
    title: "Other Information",
    panelData: [
      {
        id: 1,
        panelChilds: "Next Of Kin",
      },
      {
        id: 2,
        panelChilds: "Contact Prefrence",
      },
      {
        id: 3,
        panelChilds: "Employment Status",
      },
      {
        id: 4,
        panelChilds: "Equal Opportunity Declaration",
      },
      {
        id: 5,
        panelChilds: "Additional Docs",
      },
      {
        id: 6,
        panelChilds: "Bank Details",
      },
    ],
  },
  {
    id: 6,
    title: "Medical History",
    panelData: [
      {
        id: 1,
        panelChilds: "Immunisation",
      },
      {
        id: 2,
        panelChilds: "Medical Questionnaire",
      },
    ],
  },
  {
    id: 7,
    title: "Declaration",
    panelData: [
      {
        id: 1,
        panelChilds: "Declaration",
      },
    ],
  },
];

export const TrainingInstructorPanel = [
  {
    id: 1,
    title: "About the Candidate",
    panelData: [
      {
        id: 1,
        panelChilds: "Personal Info",
      },
      {
        id: 2,
        panelChilds: "Address Details",
      },
      {
        id: 3,
        panelChilds: "Photo for ID Badge",
      },
      {
        id: 4,
        panelChilds: "ID Upload (Passport/DL)",
      },
    ],
  },

  {
    id: 2,
    title: "References",
    panelData: [
      {
        id: 5,
        panelChilds: "Add References",
      },
    ],
  },
  {
    id: 3,
    title: "Training & Work History",
    panelData: [
      {
        id: 6,
        panelChilds: "Training Certificate",
      },
      {
        id: 7,
        panelChilds: "Additional Training Details",
      },
      {
        id: 3,
        panelChilds: "Work Experience",
      },
      {
        id: 4,
        panelChilds: "Specialities",
      },
    ],
  },
  {
    id: 4,
    title: "Background Checks",
    panelData: [
      {
        id: 1,
        panelChilds: "DBS",
      },
      {
        id: 2,
        panelChilds: "Right to Work",
      },
    ],
  },
  {
    id: 5,
    title: "Other Information",
    panelData: [
      {
        id: 1,
        panelChilds: "Employment Status",
      },
      {
        id: 2,
        panelChilds: "Additional Docs",
      },
      {
        id: 3,
        panelChilds: "Bank Details",
      },
    ],
  },

  {
    id: 7,
    title: "Declaration",
    panelData: [
      {
        id: 1,
        panelChilds: "Declaration",
      },
    ],
  },
];

export const TraingingSpecialities=[
  {
    id: 1,
    title: "Able to follow instructions & procedures",
  },
  {
    id: 2,
    title: "Able to work in a team but use Reference",
  },
  {
    id: 3,
    title: "Caring and kind",
  },
  {
    id: 4,
    title: "Observational skills",
  },
]


export const additionalTraining = [
  {
    name: "Character",
    certificateIssued: "07/04/2007",
    certificateExpiry: "07/04/2008",
    image: AdditionalDocs,
  },
  {
    name: "Employment",
    certificateIssued: "07/04/2004",
    certificateExpiry: "07/04/2005",
    image: AdditionalDocs,
  },
];

//addreference

export interface ICandidateReference {
  refType: string;
  refFullName: string;
  refContact: string;
  refEmail: string;
  createdAt: string;
}

export const applicationStage=[
  {
    value:'',
    label:'All'
  },
  {
    value:'new_application',
    label:'New Application'
  },
  {
    value:'intro_call_done',
    label:'Intro Call Done'
  },
   {
    value:'application_in_progress',
    label:'Application in Progress'
  },
   {
    value:'vetting_in_progress',
    label:'Vetting in Progress'
  },
   {
    value:'awaiting_reference',
    label:'Awaiting Prefrence'
  },
   {
    value:'interview_booked',
    label:'Interview Booked'
  },
   {
    value:'interview_done',
    label:'Interview Done'
  }, {
    value:'training_to_be_completed',
    label:'Training to be completed'
  },
  {
    value:'training_completed',
    label:'Training Completed'
  },
  {
    value:'incomplete_documents',
    label:'Incomplete Documents'
  },
  {
    value:'dbs_to_be_completed',
    label:'DBS to be completed'
  },
  {
    value:'no_experience',
    label:'No experience'
  },
  {
    value:'not_interested',
    label:'Not Interested'
  },
]