// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icons/Dashboard/divider.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".breadcrumb-wrapper {\n  padding-bottom: 15px;\n}\n@media screen and (max-width: 1024px) {\n  .breadcrumb-wrapper {\n    padding-bottom: 10px;\n  }\n}\n.breadcrumb-wrapper .seprator {\n  margin: 0 6px;\n  margin-top: 6px;\n}\n.breadcrumb-wrapper .ant-breadcrumb ol {\n  align-items: center;\n}\n.breadcrumb-wrapper .ant-breadcrumb ol li:nth-child(2) img {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.breadcrumb-wrapper .ant-breadcrumb {\n  color: #14142B;\n}\n.breadcrumb-wrapper .ant-breadcrumb a {\n  color: #6E7191;\n  font-size: 14px;\n  font-weight: 400;\n}\n.breadcrumb-wrapper .ant-breadcrumb a:hover {\n  background: transparent;\n}", "",{"version":3,"sources":["webpack://./src/layout/BreadCrumb/BreadCrumb.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AAAE;EAFF;IAGI,oBAAA;EAGF;AACF;AAFE;EACE,aAAA;EACA,eAAA;AAIJ;AAFE;EACE,mBAAA;AAIJ;AAFE;EACE,gDAAA;AAIJ;AAFE;EACE,cAAA;AAIJ;AAFE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAIJ;AAFE;EACE,uBAAA;AAIJ","sourcesContent":[".breadcrumb-wrapper{\r\n  padding-bottom: 15px;\r\n  @media screen and (max-width: 1024px) {\r\n    padding-bottom: 10px;\r\n  }\r\n  .seprator{\r\n    margin: 0 6px;\r\n    margin-top: 6px;\r\n  }\r\n  .ant-breadcrumb ol{\r\n    align-items: center;\r\n  }\r\n  .ant-breadcrumb ol li:nth-child(2) img {\r\n    content:url(\"../../assets/icons/Dashboard/divider.png\")\r\n  }\r\n  .ant-breadcrumb{\r\n    color: #14142B;\r\n  }\r\n  .ant-breadcrumb a{\r\n    color: #6E7191;\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n  }\r\n  .ant-breadcrumb a:hover{\r\n    background: transparent;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
