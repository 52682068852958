import { Rule } from "antd/lib/form";
import debounce from "lodash.debounce";
import { NEW_ROLES, ROLES } from "../constants/Roles";


// ================================= Check if Value is Null or Empty =================================
export function isNullOrEmpty(value: any): boolean {
  if (value === undefined || value === null) {
    return true;
  }
  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true;
  }
  return false;
}



// ================================= Debounce Technique =================================
const DEBOUNCE_DELAY = 1000;

export const debouncedSearch = debounce((value, setSearchName) => {
  setSearchName(value);
}, DEBOUNCE_DELAY);






// ================================= For Getting Role Label =================================
export const GetRoleLabel = (role: string) => {
  return NEW_ROLES.find((item: any) => {
    return role === item.name &&
    {
      name: item?.name,
      label: item?.label
    }
  })
}



// ================================= Input Rule =================================
export const FormInputRules = (name: string, rules?: Rule[]) => {
  return {
    name,
    // ================= Incase if label is Required =================
    // rules: rules ?? [{ required: true, message: `${label} is required` }],

    rules: rules ?? [{ required: true, message: `Required field` }],
  };
};





// ================================= Not Allowing Empty Spaces in Start of the Input =================================
export const handleInputTrimStart = (value: any) => {
  return value.trimStart();
};

// ================================= Not Allowing long Empty Spaces in the Input =================================
export const handleInputTrimSpaces = (value: any) => {
  if (typeof value === 'string') {
    // ----- For single input fields -----
    return value.trim().replace(/\s+/g, ' ');
  } else if (typeof value === 'object') {

    // ----- For whole form objects -----
    const trimmedValues: any = {};

    // ----- Loop through the objects values -----
    for (const [key, val] of Object.entries(value)) {

      // ----- Trim the value if it's a string. If the value is not a string, leave it as it is -----
      if (typeof val === 'string') {
        trimmedValues[key] = val.trim().replace(/\s+/g, ' ');
      } else {
        trimmedValues[key] = val;
      }
    }

    return trimmedValues;
  } else {
    return value;
  }
};

// ================================= Render Dashboard According to the Role =================================
export const renderDashboard = (role: string) => {

  const returnDashboard: any = {
    "carer": "/carer-dashboard",
    "carer_coordinator" : "/coordinator-dashboard",
    "client": "/client-dashboard",
    "training_instructor": "/instructor-dashboard",
    "superAdmin": "/super-admin-dashboard"
  }

  // // Return the above, if there's isn't return /dashboard (Admin)
  return returnDashboard[role] || "/dashboard";

}